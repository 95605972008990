import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'delete-project-quote-modal',
    emits: ['submit', 'cancel'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
        submitted: Boolean,
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        submit() {
            this.$emit('submit');
        }
    }
});

import { defineComponent } from 'vue';
import QuoteStageTaskFormComponent from '@/modules/project-quote-create/components/QuoteStageTaskFormComponent.vue';
import { stageCostValidator, subTotalValidator, totalDurationValidator } from '@/modules/project-quote-create/validators';
export default defineComponent({
    name: 'quote-stage-form-component',
    components: {
        QuoteStageTaskFormComponent,
    },
    emits: ['add-task', 'remove-task', 'open-remove-stage-modal'],
    props: {
        quoteRecord: {
            type: Object,
            default: () => { }
        },
        stage: {
            type: Object,
            default: () => { }
        },
        stageIndex: {
            type: Number,
        },
        selectedStageIndex: Number,
        updatingStage: {
            type: Boolean
        },
        editMode: {
            type: Boolean,
        },
        uploadHeaders: {
            type: Object,
            default: () => { }
        },
        canUpdate: {
            type: Boolean,
        },
        apiUrl: {
            type: String
        },
        uploadedFiles: {
            type: Array
        },
        updatingQuote: {
            type: Boolean
        }
    },
    computed: {
        stageEntity() {
            return this.stage;
        },
        quoteEntity() {
            return this.quoteRecord;
        }
    },
    created() {
        const { stageEntity } = this;
        const isStageCostFilled = this.isStageCostFilled();
        const isTotalDurationFilled = this.isTotalDurationFilled();
        if (isStageCostFilled) {
            stageEntity.enableCost = isStageCostFilled;
            stageEntity.subTotal = this.convertToDecimal(stageEntity.subTotal);
        }
        if (isTotalDurationFilled) {
            stageEntity.enableDuration = isTotalDurationFilled;
            stageEntity.totalDuration = this.convertToDecimal(stageEntity.totalDuration);
        }
    },
    data() {
        return {
            quoteStageTaskFormRefs: [],
            quoteRecordGeneralFormRules: {
                suggestedStartDate: [
                    { required: true, message: 'Suggested Start Date must be populated.', trigger: 'blur' },
                ]
            },
            stageFormRules: {
                name: [
                    { required: true, message: 'Stage name is required.', trigger: 'blur' },
                    { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
                ],
                suggestedStartDate: [
                    { required: true, message: 'Suggested Start Date is required.', trigger: 'blur' },
                ],
            },
            stageForm2Rules: {
                stageCost: [
                    { required: true, validator: stageCostValidator, trigger: 'blur' },
                ],
                subTotal: [
                    { required: true, validator: subTotalValidator, trigger: 'blur' },
                ],
                totalDuration: [
                    { required: true, validator: totalDurationValidator, trigger: 'blur' },
                ],
            },
            // Need to identify what's 0. 5, and 20. Refactor this to be variable or any that will give hint to devs what's this
            vatAvailable: [0, 5, 20],
            showDeleteStageDialog: false,
        };
    },
    beforeUpdate() {
        this.quoteStageTaskFormRefs = [];
    },
    methods: {
        addNewTaskOnStage(stageIndex) {
            this.$emit('add-task', stageIndex);
        },
        removeTask(payload) {
            this.$emit('remove-task', payload);
        },
        setQuoteStageTaskFormRefs(el) {
            const exists = this.quoteStageTaskFormRefs.includes(el);
            if (!exists) {
                // TODO: find alternative way to fix performance issue
                this.quoteStageTaskFormRefs.push(el);
            }
        },
        async updateStage() { },
        onShowDeleteStageDialog(stageIndex) {
            this.$emit('open-remove-stage-modal', stageIndex);
        },
        isNumber(event, value) {
            let currentValue = value;
            let evt = event;
            if (typeof currentValue !== 'undefined' && currentValue !== null) {
                currentValue = currentValue.toString();
            }
            evt = evt ?? window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }
            else if (charCode === 46 && currentValue && currentValue.toString().indexOf('.') !== -1) {
                evt.preventDefault();
            }
            else if (currentValue && currentValue.indexOf('.') > -1 && (currentValue.split('.')[1].length > 1)) {
                const selection = window.getSelection();
                if (selection && !(selection.type === 'Range' && selection.anchorNode === evt.target.parentElement)) {
                    evt.preventDefault();
                }
            }
        },
        convertToDecimal(value) {
            let numberValue = parseFloat(value);
            if (Number.isNaN(numberValue)) {
                numberValue = 0;
            }
            return numberValue.toFixed(2);
        },
        checkTaskDurations(isEnable, stageIndex) {
            this.quoteEntity.projectStages[stageIndex].projectStageTasks.forEach((task, i) => {
                this.quoteEntity.projectStages[stageIndex].projectStageTasks[i].duration = '';
            });
            this.quoteEntity.projectStages[stageIndex].totalDuration = !isEnable ? 0 : '';
        },
        checkTaskCosts(isEnable, stageIndex) {
            this.quoteRecord.projectStages[stageIndex].projectStageTasks.forEach((task, i) => {
                this.quoteEntity.projectStages[stageIndex].projectStageTasks[i].amount = '';
            });
            this.quoteEntity.projectStages[stageIndex].subTotal = !isEnable ? 0 : '';
            this.quoteEntity.projectStages[stageIndex].stageCost = 0;
        },
        updateTotalStageCost(stageIndex) {
            this.quoteEntity.projectStages[stageIndex].stageCost = this.getCalculatedStageTotalCostWithVat(stageIndex);
        },
        getCalculatedStageTotalCostWithVat(stageIndex) {
            let total = 0;
            if (this.quoteRecord.projectStages[stageIndex].projectStageTasks.length) {
                const stageSubTotal = parseFloat(this.quoteRecord.projectStages[stageIndex].subTotal);
                const vat = parseFloat(this.quoteRecord.projectStages[stageIndex].vat);
                const vatAmount = (vat * stageSubTotal) / 100;
                total = total + stageSubTotal + vatAmount;
            }
            return Number.isNaN(parseFloat(total.toString())) ? 0 : total;
        },
        getCalculatedStageSubTotal(stageIndex) {
            let total = 0;
            const stage = this.quoteRecord.projectStages[stageIndex];
            if (stage.enableCost) {
                return Number.isNaN(parseFloat(stage.subTotal)) ? 0 : parseFloat(stage.subTotal);
            }
            if (stage.projectStageTasks.length) {
                this.quoteRecord.projectStages[stageIndex].projectStageTasks.forEach((task) => {
                    total += parseFloat(task.amount);
                });
                if (total === 0) {
                    return parseFloat(this.quoteRecord.projectStages[stageIndex].subTotal);
                }
            }
            return Number.isNaN(parseFloat(total.toString())) ? 0 : total;
        },
        updateStageSubTotalCost(stageIndex) {
            this.quoteEntity.projectStages[stageIndex].subTotal = this.getCalculatedStageSubTotal(stageIndex).toFixed(2);
            this.updateTotalStageCost(stageIndex);
        },
        isStageCostFilled() {
            const { projectStageTasks } = this.stage;
            const filled = projectStageTasks
                .filter((item) => item.amount !== null && item.amount === 0);
            return filled.length > 0;
        },
        isTotalDurationFilled() {
            const { projectStageTasks } = this.stage;
            const filled = projectStageTasks
                .filter((item) => item.duration !== null && item.duration === 0);
            return filled.length > 0;
        }
    }
});

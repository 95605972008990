import { Plus } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { API_URL } from '@/config';
import QuoteSummaryCard from '@/core/components/project/project-card-statuses/ProjectQuoteSummaryCard.vue';
import DeleteProjectQuoteModal from '@/core/components/project-quote/modals/DeleteProjectQuoteModal.vue';
import { AUTH_TOKEN } from '@/core/constants';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import QuoteDetailFormComponent from '@/modules/project-quote-create/components/QuoteDetailFormComponent.vue';
import QuoteStageFormComponent from '@/modules/project-quote-create/components/QuoteStageFormComponent.vue';
import CancelProjectQuoteConfirmationModal from '@/modules/project-quote-create/modals/CancelProjectQuoteConfirmationModal.vue';
import CreateProjectQuoteConfirmationModal from '@/modules/project-quote-create/modals/CreateProjectQuoteConfirmationModal.vue';
import DeleteProjectStageModal from '@/modules/project-quote-create/modals/DeleteProjectStageModal.vue';
import { cloneObject, getLocalStorageWithExpiry } from '../../../core/utils/common';
import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
import { PROJECT_OVERVIEW_ROUTE } from '../../project-overview/routes';
import { PROJECTS_ROUTE } from '../../projects/routes';
export default defineComponent({
    name: 'project-quote-create-page',
    components: {
        QuoteDetailFormComponent,
        QuoteStageFormComponent,
        QuoteSummaryCard,
        CreateProjectQuoteConfirmationModal,
        CancelProjectQuoteConfirmationModal,
        DeleteProjectStageModal,
        DeleteProjectQuoteModal,
        Plus
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(PROJECT_QUOTES_STORE, [
            'defaultProjectQuoteState',
            'defaultStageState',
            'defaultTaskState'
        ]),
        setChooseDay() {
            return {
                disabledDate(date) {
                    return moment(date).utc() < moment().startOf('day');
                }
            };
        },
        totalStageAmount() {
            const stageAmounts = this.projectQuoteRecord.projectStages.map((stage) => {
                return stage.stageCost || 0;
            });
            const totalStageAmount = stageAmounts.reduce((acc, curr) => {
                return acc + curr;
            }, 0);
            return totalStageAmount;
        },
        canCancel() {
            return this.projectQuoteRecord.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_SUBMITTED && this.projectQuoteRecord.projectQuoteCreator.id != null;
        },
        canEditOrSubmitQuote() {
            const allowedToEditOrSubmit = [
                ProjectQuoteStatusEnum.DRAFT_QUOTE,
                ProjectQuoteStatusEnum.QUOTE_SUBMITTED,
                ProjectQuoteStatusEnum.QUOTE_REJECTED,
                ProjectQuoteStatusEnum.CANCELLED,
                ProjectQuoteStatusEnum.PROJECT_REJECTED,
            ].includes(this.projectQuoteRecord.projectQuoteStatus?.id);
            return (allowedToEditOrSubmit) || (this.projectQuoteRecord?.projectQuoteCreator?.id == null);
        },
        canDelete() {
            const { projectQuoteRecord, user } = this;
            if (!projectQuoteRecord)
                return false;
            const allowedToDelete = [
                ProjectQuoteStatusEnum.DRAFT_QUOTE,
                ProjectQuoteStatusEnum.CANCELLED,
                ProjectQuoteStatusEnum.QUOTE_REJECTED,
                ProjectQuoteStatusEnum.PROJECT_REJECTED,
            ].includes(projectQuoteRecord.projectQuoteStatus?.id);
            return allowedToDelete && user.id === projectQuoteRecord?.projectQuoteCreator?.id;
        },
        canUpdate() {
            const { projectQuoteRecord } = this;
            if (!projectQuoteRecord)
                return false;
            const allowedToUpdate = [
                ProjectQuoteStatusEnum.DRAFT_QUOTE,
                ProjectQuoteStatusEnum.QUOTE_SUBMITTED,
                ProjectQuoteStatusEnum.QUOTE_ACCEPTED,
            ].includes(projectQuoteRecord.projectQuoteStatus?.id);
            return (allowedToUpdate) || projectQuoteRecord?.projectQuoteCreator?.id === undefined;
        },
        canSaveAsDraft() {
            const { projectQuoteRecord } = this;
            const canSaveDraft = [
                ProjectQuoteStatusEnum.QUOTE_SUBMITTED,
            ].includes(projectQuoteRecord.projectQuoteStatus?.id);
            return (canSaveDraft && projectQuoteRecord?.projectQuoteCreator.id === this.user?.id) || !projectQuoteRecord?.id;
        },
        projectQuoteState() {
            const defaultProjectQuoteState = cloneObject(this.defaultProjectQuoteState);
            const defaultStageState = cloneObject(this.defaultStageState);
            const defaultTaskState = cloneObject(this.defaultTaskState);
            defaultStageState.projectStageTasks.push(defaultTaskState);
            defaultProjectQuoteState.projectStages.push(defaultStageState);
            return defaultProjectQuoteState;
        },
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        },
    },
    data() {
        return {
            ProjectQuoteStatusEnum,
            projectQuoteRecord: null,
            apiUrl: API_URL,
            attachmentUrls: [],
            isLoadingProjectQuote: null,
            openDeleteProjectStageModal: false,
            selectedStageIndex: 0,
            showProjectQuoteModal: false,
            showCancelProjectQuoteModal: false,
            showDeleteProjectQuoteModal: false,
            confirmDeleteProjectQuoteSubmit: false,
            quoteStageFormComponentRefs: [],
            selectedProject: null,
            submitting: false,
        };
    },
    beforeUpdate() {
        this.quoteStageFormComponentRefs = [];
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(PROJECT_QUOTES_STORE, ['getQuotedProjectByAuthUser', 'createProjectQuote', 'updateProjectQuote',
            'deleteProjectQuote', 'updateProjectQuoteStatus']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus']),
        async initialize() {
            const { projectRefId } = this.$route.params;
            this.isLoadingProjectQuote = true;
            await this.getProjectByRefId(projectRefId)
                .then((response) => {
                if (response) {
                    this.selectedProject = response;
                    this.setSelectedProjectJobStatus(this.selectedProject?.projectJobStatus);
                }
            })
                .catch((error) => {
                this.$notify.error({
                    message: error && error?.message ? error?.message : 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            });
            if (this.selectedProject && this.selectedProject?.projectQuotes?.length) {
                const [projectQuote] = this.selectedProject.projectQuotes;
                this.projectQuoteRecord = projectQuote;
            }
            else {
                this.projectQuoteRecord = this.projectQuoteState;
                this.projectQuoteRecord.projectId = this.selectedProject?.id;
            }
            this.isLoadingProjectQuote = false;
        },
        openDeleteProjectStage(stageIndex) {
            this.selectedStageIndex = stageIndex;
            this.openDeleteProjectStageModal = true;
        },
        setQuoteStageFormComponentRefs(el) {
            if (el) {
                const exists = this.quoteStageFormComponentRefs.includes(el);
                if (!exists) {
                    // TODO: find alternative way to fix performance issue
                    this.quoteStageFormComponentRefs.push(el);
                }
            }
        },
        confirmUpdateQuote(projectQuoteRecord) {
            this.validateProjectQuote(projectQuoteRecord);
        },
        addNewStage() {
            const stage = cloneObject(this.defaultStageState);
            const task = cloneObject(this.defaultTaskState);
            stage.projectStageTasks.push(task);
            this.projectQuoteRecord.projectStages.push(stage);
        },
        addTask(stageIndex) {
            const task = cloneObject(this.defaultTaskState);
            this.projectQuoteRecord.projectStages[stageIndex].projectStageTasks.push(task);
        },
        removeTask({ stageIndex, taskIndex }) {
            const stages = this.projectQuoteRecord.projectStages;
            const selectedStage = stages[stageIndex];
            selectedStage.projectStageTasks.splice(taskIndex, 1);
        },
        removeStage() {
            this.projectQuoteRecord.projectStages.splice(this.selectedStageIndex, 1);
            this.openDeleteProjectStageModal = false;
        },
        validateProjectQuote(projectQuoteRecord) {
            this.$refs.quoteFormComponent.$refs.quoteForm.validate(async (mainValid) => {
                if (!mainValid) {
                    return false;
                }
                const promises = [];
                this.quoteStageFormComponentRefs.forEach((quoteStageForm) => {
                    // eslint-disable-next-line no-async-promise-executor
                    const promise = new Promise(async (mainResolve) => {
                        let valid = true;
                        const stageFormValidityPromises = [];
                        // store stage form validity in array as promise
                        ['stageForm1', 'stageForm2'].forEach(async (stageForm) => {
                            const form = quoteStageForm.$refs[stageForm];
                            stageFormValidityPromises.push(new Promise((resolve) => {
                                if (form) {
                                    form.validate((validStageForm) => {
                                        resolve(validStageForm);
                                    });
                                }
                                else {
                                    resolve(true);
                                }
                            }));
                        });
                        const stageFormValidity = await Promise.all(stageFormValidityPromises);
                        // check task form validity
                        if (stageFormValidity.some((validForm) => !validForm)) {
                            valid = false;
                        }
                        const taskFormValidityPromises = [];
                        // store task form validity in array as PRomie
                        quoteStageForm.quoteStageTaskFormRefs.forEach((quoteStageTaskForm) => {
                            if (quoteStageTaskForm) {
                                const form = quoteStageTaskForm.$refs.taskForm;
                                taskFormValidityPromises.push(new Promise((resolve) => {
                                    if (form) {
                                        form.validate((validQuoteStageForm) => {
                                            resolve(validQuoteStageForm);
                                        });
                                    }
                                    else {
                                        resolve(true);
                                    }
                                }));
                            }
                        });
                        const taskFormValidity = await Promise.all(taskFormValidityPromises);
                        // check task form validity
                        if (taskFormValidity.some((validForm) => !validForm)) {
                            valid = false;
                        }
                        mainResolve(valid);
                    });
                    promises.push(promise);
                });
                const promiseRes = await Promise.all(promises);
                const hasErrors = promiseRes.some((val) => val === false);
                if (!hasErrors) {
                    this.saveProjectQuoteRecord(projectQuoteRecord);
                }
                return true;
            });
        },
        saveProjectQuoteRecord(projectQuoteRecord) {
            if (projectQuoteRecord.id
                && projectQuoteRecord.projectQuoteCreator.id === this.user.id) {
                this.updateProjectQuoteRecord(projectQuoteRecord);
            }
            else {
                this.showProjectQuoteModal = true;
            }
        },
        // TODO: parse each object in a separate method for cleaner transformation of data
        onConfirmCreateProjectQuote(isSaveDraft) {
            const projectQuote = cloneObject(this.projectQuoteRecord);
            if (projectQuote.projectStages.length) {
                const projectStages = projectQuote.projectStages.map((item) => {
                    const copyItem = { ...item };
                    // TODO: don't stringify this
                    copyItem.attachments = JSON.stringify(item.attachments);
                    copyItem.totalDuration = window.parseFloat(item.totalDuration);
                    copyItem.subTotal = window.parseFloat(item.subTotal);
                    delete copyItem.stageCostType;
                    delete copyItem.enableDuration;
                    delete copyItem.enableCost;
                    const projectStageTask = item.projectStageTasks.map((stageTask) => {
                        const task = { ...stageTask };
                        task.duration = !Number.isNaN(stageTask.duration) ? window.parseInt(stageTask.duration) : 0;
                        task.amount = !Number.isNaN(stageTask.amount) ? window.parseFloat(stageTask.amount) : 0;
                        if (Number.isNaN(task.amount)) {
                            task.amount = 0;
                        }
                        if (Number.isNaN(task.duration)) {
                            task.duration = 0;
                        }
                        return task;
                    });
                    copyItem.projectStageTasks = projectStageTask;
                    return copyItem;
                });
                projectQuote.projectStages = projectStages;
            }
            projectQuote.projectQuoteStatusId = isSaveDraft ? ProjectQuoteStatusEnum.DRAFT_QUOTE : ProjectQuoteStatusEnum.QUOTE_SUBMITTED;
            const { projectRefId } = this.$route.params;
            this.submitting = true;
            this.createProjectQuote({ projectId: this.selectedProject?.id, formData: projectQuote })
                .then((response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Quote Creation Successful',
                        message: 'You successfully submitted your quote to the project.'
                    });
                    this.showProjectQuoteModal = false;
                    this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error Project Quote Creation',
                    message: e?.data?.message ?? 'Error occurred when submitting the quote. Please try again.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        updateProjectQuoteRecord(projectQuoteRecord) {
            const projectQuote = cloneObject(projectQuoteRecord);
            if (projectQuote.projectStages.length) {
                const projectStages = projectQuote.projectStages.map((item) => {
                    const copyItem = { ...item };
                    const removeUnnecessaryStageUpdateFields = ['id', 'dateCreated', 'dateUpdated', 'projectQuoteId',
                        'projectStageStatusId', 'refId', 'chipMessageStatus', 'stageCostType', 'projectStageStatus', 'projectStageAttachments',
                        'enableDuration', 'enableCost', 'attachments'
                    ];
                    removeUnnecessaryStageUpdateFields.forEach((field) => {
                        delete copyItem[field];
                    });
                    copyItem.totalDuration = window.parseFloat(item.totalDuration);
                    copyItem.subTotal = window.parseFloat(item.subTotal);
                    const projectStageTask = item.projectStageTasks.map((stageTask) => {
                        const task = { ...stageTask };
                        const removeUnnecessaryTaskUpdateFields = ['id', 'dateCreated', 'dateUpdated', 'taskType', 'done'];
                        removeUnnecessaryTaskUpdateFields.forEach((field) => {
                            delete task[field];
                        });
                        task.duration = !Number.isNaN(stageTask.duration) ? window.parseInt(stageTask.duration) : 0;
                        task.amount = !Number.isNaN(stageTask.amount) ? window.parseFloat(stageTask.amount) : 0;
                        if (Number.isNaN(task.amount)) {
                            task.amount = 0;
                        }
                        if (Number.isNaN(task.duration)) {
                            task.duration = 0;
                        }
                        return task;
                    });
                    copyItem.projectStageTasks = projectStageTask;
                    return copyItem;
                });
                projectQuote.projectStages = projectStages;
            }
            delete projectQuote?.agreeTermsConsumerDisclosure;
            if (projectQuote.projectQuoteStatus?.id === ProjectQuoteStatusEnum.DRAFT_QUOTE
                || projectQuote.projectQuoteStatus?.id === ProjectQuoteStatusEnum.CANCELLED
                || projectQuote.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_REJECTED
                || projectQuote.projectQuoteStatus?.id === ProjectQuoteStatusEnum.PROJECT_REJECTED) {
                projectQuote.projectQuoteStatusId = ProjectQuoteStatusEnum.QUOTE_SUBMITTED;
            }
            delete projectQuote.projectQuoteStatus;
            delete projectQuote.projectQuoteCreator;
            projectQuote.projectQuoteAttachments = this.cleanAttachmentData(projectQuote.projectQuoteAttachments);
            projectQuote.projectId = this.selectedProject?.id;
            const { projectRefId } = this.$route.params;
            this.submitting = true;
            this.updateProjectQuote({ projectId: this.selectedProject?.id, projectQuoteId: projectQuote.id, formData: projectQuote })
                .then((response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Quote Update Successful',
                        message: 'You successfully updated your quote details.',
                    });
                    if (projectQuoteRecord?.projectQuoteStatus && projectQuoteRecord?.projectQuoteStatus?.id === ProjectQuoteStatusEnum.DRAFT_QUOTE) {
                        this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
                    }
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error Project Quote Creation',
                    message: e?.data?.message ?? 'Error occurred when submitting the quote. Please try again.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        onConfirmQuoteSubmit(projectQuoteRecord) {
            this.validateProjectQuote(projectQuoteRecord);
        },
        onConfirmQuoteCancel() {
            this.submitting = true;
            const { projectRefId } = this.$route.params;
            const formData = {
                projectQuoteStatusId: ProjectQuoteStatusEnum.CANCELLED
            };
            this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId: this.projectQuoteRecord.id, formData })
                .then(() => {
                this.$notify.success({
                    title: 'Project Quote Status Update Successful',
                    message: 'You successfully canceled your quote in this project.'
                });
                this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error',
                    message: e?.data?.message ?? 'Error deleting quote details. Please try again.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        onConfirmDeleteProjectQuote() {
            this.submitting = true;
            const { projectRefId } = this.$route.params;
            this.deleteProjectQuote({ projectId: this.selectedProject?.id, projectQuoteId: this.projectQuoteRecord.id })
                .then(() => {
                this.$notify.success({
                    title: 'Project Quote Delete Successful',
                    message: 'You successfully deleted your quote in this project.'
                });
                this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error',
                    message: e?.data?.message ?? 'Error deleting quote details. Please try again.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        saveProjectQuoteAsDraft() {
            this.validateProjectQuote(this.projectQuoteRecord);
            if (!this.projectQuoteRecord?.id && this.projectQuoteRecord.projectQuoteStatusId === undefined) {
                // save as draft pass true
                this.onConfirmCreateProjectQuote(true);
                return;
            }
            const { projectRefId } = this.$route.params;
            const formData = {
                projectQuoteStatusId: ProjectQuoteStatusEnum.DRAFT_QUOTE
            };
            this.submitting = true;
            this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId: this.projectQuoteRecord.id, formData })
                .then(() => {
                this.$notify.success({
                    title: 'Project Quote Status Update Successful',
                    message: 'You successfully set your quote to Draft.'
                });
                this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error',
                    message: e?.data?.message ?? 'Error updating quote status to Draft. Please try again.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        handleDeleteProjectQuote() {
            this.showDeleteProjectQuoteModal = true;
        },
        cleanAttachmentData(attachments) {
            return attachments.map((attachment) => {
                const current = attachment;
                delete current.src;
                return {
                    ...current
                };
            });
        },
    },
});

import { Delete, Document, Plus } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { DocumentFileTypes, ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_QUOTES_CACHE } from '@/store/modules/attachment-cache/constants';
import { DATE_FORMAT } from '../../../core/constants';
export default defineComponent({
    name: 'quote-detail-form-component',
    props: {
        quoteRecord: {
            type: Object,
            default: () => { }
        },
        canUpdate: {
            type: Boolean,
        },
        apiUrl: {
            type: String
        },
        uploadHeaders: {
            type: Object,
            default: () => { }
        },
        updatingQuote: {
            type: Boolean
        },
        setChooseDay: {
            type: Object,
        }
    },
    components: {
        ImageDynamicLayout,
        ImageLoading,
        ImageFailedLoading,
        Plus,
        Delete,
        Document
    },
    computed: {
        formRecord() {
            return this.quoteRecord;
        }
    },
    data() {
        return {
            DATE_FORMAT,
            quoteRecordGeneralFormRules: {
                suggestedStartDate: [
                    { required: true, message: 'Suggested Start Date must be populated.', trigger: 'blur' },
                ]
            },
            uploadedFiles: [],
            attachmentsProcessed: false,
            currentBlobUrl: '',
            disabledDate(date) {
                return date < moment(new Date()).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toDate();
            },
            failedUpload: false,
            documentFileTypes: DocumentFileTypes,
            isAttachmentRequestComplete: true
        };
    },
    created() {
        this.loadAttachments();
    },
    watch: {
        $route() {
            const keepList = [
                'projects/overview',
                'projects/quote-builder',
                'messages'
            ];
            const { name } = this.$route;
            if (keepList.indexOf(name) === -1) {
                this.reset();
            }
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments', 'resetCacheEntity']),
        onUploadProgress(response, file) {
            const attachment = file;
            attachment.isLoading = true;
            attachment.isError = false;
            this.currentBlobUrl = file.url;
        },
        onUploadSuccess(response, file, fileList) {
            const attachment = file;
            const record = this.formRecord;
            const newRecord = response;
            attachment.isLoading = false;
            attachment.isError = false;
            attachment.record = response;
            this.uploadedFiles = fileList;
            if (record.projectQuoteAttachments == null) {
                record.projectQuoteAttachments = [];
            }
            newRecord.uid = file.uid;
            record.projectQuoteAttachments.push(newRecord);
        },
        onRemoveFile(file) {
            const record = this.formRecord;
            const currentList = record.projectQuoteAttachments;
            const updatedList = [];
            for (let i = 0; i < currentList.length; i++) {
                const currentFile = currentList[i];
                const hasUid = currentFile && currentFile.uid;
                const { response, attachment } = file;
                if ((attachment && attachment !== currentFile.attachment)
                    || (response && response.attachment && currentFile.attachment !== response.attachment)
                    || (hasUid && currentFile.uid !== file.uid)) {
                    updatedList.push(currentFile);
                }
            }
            record.projectQuoteAttachments = updatedList;
            this.uploadedFiles = updatedList;
        },
        onUploadError(err) {
            this.$notify.error({
                title: 'Failed to upload, please try again.',
                message: err
            });
        },
        reset() {
            this.uploadedFiles = [];
            this.attachmentsProcessed = false;
        },
        isImageAttachment(file) {
            let { attachment: filename } = file;
            const { name } = file;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return ImageFileTypes.includes(extension.toLowerCase());
        },
        isPdfFile(file) {
            let { attachment: filename } = file;
            const { name } = file;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            return filename && filename.indexOf('.pdf') > -1;
        },
        loadAttachments() {
            const { projectQuoteAttachments } = this.formRecord;
            const record = this.formRecord;
            if (projectQuoteAttachments?.length) {
                this.isAttachmentRequestComplete = false;
                this.getAttachments({
                    name: PROJECT_QUOTES_CACHE,
                    attachments: projectQuoteAttachments,
                    includeUrl: true
                })
                    .then((values) => {
                    const newValues = values.filter(Boolean);
                    if (newValues.length) {
                        this.uploadedFiles = newValues;
                        record.projectQuoteAttachments = newValues.map((item) => {
                            const attachment = this.getQuoteAttachmentId(item.attachment);
                            return {
                                ...item,
                                id: attachment[0]?.id
                            };
                        });
                    }
                    this.isAttachmentRequestComplete = true;
                })
                    .catch(() => { })
                    .finally(() => { this.isAttachmentRequestComplete = true; });
            }
        },
        getQuoteAttachmentId(attachment) {
            const { projectQuoteAttachments } = this.formRecord;
            return projectQuoteAttachments.filter((item) => {
                return item.attachment === attachment;
            });
        },
    }
});

/**
 * Stage cost validator
 * @param rule
 * @param value
 * @param cb
 */
// eslint-disable-next-line no-unused-vars
export const stageCostValidator = (rule, value, cb) => {
    const numberValue = parseFloat(value);
    if (!value || Number.isNaN(numberValue) || numberValue <= 0) {
        return cb(new Error('Stage cost is required.'));
    }
    return cb();
};
/**
 * Sub Total validator
 * @param rule
 * @param value
 * @param cb
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export const subTotalValidator = (rule, value, cb) => {
    const numberValue = parseFloat(value);
    if (!value || Number.isNaN(numberValue) || numberValue <= 0) {
        return cb(new Error(' '));
    }
    return cb();
};
// eslint-disable-next-line no-unused-vars
export const totalDurationValidator = (rule, value, cb) => {
    const numberValue = parseFloat(value);
    if (!value || Number.isNaN(numberValue) || numberValue <= 0) {
        return cb(new Error(' '));
    }
    return cb();
};

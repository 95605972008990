import { defineComponent } from 'vue';
export default defineComponent({
    name: 'quote-stage-task-form-component',
    emits: ['add-task', 'remove-task'],
    props: {
        quoteRecord: {
            type: Object,
            default: () => { }
        },
        stage: {
            type: Object,
            default: () => { }
        },
        stageIndex: {
            type: Number,
        },
        task: {
            type: Object,
            default: () => { }
        },
        taskIndex: {
            type: Number,
        },
        editMode: {
            type: Boolean,
        },
        showInputLabel: {
            type: Boolean,
            default: true,
        },
        showBottomBorder: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            taskFormRules: {
                name: [
                    { required: true, message: 'Description required', trigger: 'blur' },
                    { max: 255, message: 'Please enter a maximum of 255 characters.', trigger: 'blur' },
                ],
                amount: [
                    { required: true, message: 'Cost required', trigger: 'blur' },
                ],
                duration: [
                    { required: true, message: 'Duration required', trigger: 'blur' },
                ],
            },
        };
    },
    computed: {
        quoteEntity() {
            return this.quoteRecord;
        },
        taskState() {
            return this.task;
        }
    },
    methods: {
        isNumber(event, value) {
            let currentValue = value;
            if (typeof currentValue !== 'undefined' && currentValue !== null) {
                currentValue = currentValue.toString();
            }
            const evt = (event != null) ? event : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }
            else if (charCode === 46 && currentValue && currentValue.toString().indexOf('.') !== -1) {
                evt.preventDefault();
            }
        },
        getTotalStageDuration(stageIndex) {
            let total = 0;
            if (this.quoteRecord.projectStages[stageIndex].projectStageTasks.length) {
                this.quoteRecord.projectStages[stageIndex].projectStageTasks.forEach((task) => {
                    const duration = parseFloat(task.duration);
                    total += !Number.isNaN(duration) ? duration : 0;
                });
            }
            return total.toFixed(2);
        },
        updateStageTotalDuration(currentValue, stageIndex) {
            this.quoteEntity.projectStages[stageIndex].totalDuration = this.getTotalStageDuration(stageIndex);
        },
        updateTotalStageCost(stageIndex) {
            this.quoteEntity.projectStages[stageIndex].stageCost = this.getCalculatedStageTotalCostWithVat(stageIndex);
        },
        getCalculatedStageTotalCostWithVat(stageIndex) {
            let total = 0;
            if (this.quoteRecord.projectStages[stageIndex].projectStageTasks.length) {
                const stageSubTotal = parseFloat(this.getCalculatedStageSubTotal(stageIndex));
                const vat = parseFloat(this.quoteRecord.projectStages[stageIndex].vat);
                const vatAmount = (vat * stageSubTotal) / 100;
                total = total + stageSubTotal + vatAmount;
            }
            return Number.isNaN(parseFloat(total.toString())) ? 0 : total;
        },
        getCalculatedStageSubTotal(stageIndex) {
            let total = 0;
            if (this.quoteRecord.projectStages[stageIndex].projectStageTasks.length) {
                this.quoteRecord.projectStages[stageIndex].projectStageTasks.forEach((task) => {
                    const amount = parseFloat(task.amount);
                    total += !Number.isNaN(amount) ? amount : 0;
                });
            }
            return total.toFixed(2);
        },
        updateStageSubTotalCost(stageIndex) {
            this.quoteEntity.projectStages[stageIndex].subTotal = this.getCalculatedStageSubTotal(stageIndex);
            this.updateTotalStageCost(stageIndex);
        },
        removeStageTask(payload) {
            this.$emit('remove-task', payload);
            this.updateStageSubTotalCost(payload.stageIndex);
            this.updateStageTotalDuration(null, payload.stageIndex);
        },
        convertToDecimal(value) {
            let numberValue = parseFloat(value);
            if (Number.isNaN(numberValue)) {
                numberValue = 0;
            }
            return numberValue.toFixed(2);
        },
    }
});

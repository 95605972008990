import { QuestionFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'cancel-project-quote-confirmation-modal',
    emits: ['cancel', 'submit'],
    components: {
        BaseModal,
        QuestionFilled
    },
    props: {
        show: Boolean,
        loading: Boolean,
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        submit() {
            this.$emit('submit');
        }
    }
});
